import React, {useEffect, useContext} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./KioskLaborTrackPage.scss"
import Title from "./Title";
import Banner from "./Banner";
import ScanForm from "./ScanForm";
import Center from "./Center";
import {useParams, useHistory} from "react-router";
import AppConfigContext, { checkFeatureEnabled } from "../../store/app-config";
import AdminContext from "../../store/admin-context";
import {FEATURE_ADMIN_SCAN_AND_GO}from "../../constants";

export default function KioskLaborTrackPage() {
    let { nodeId } = useParams<{ nodeId: string }>();
    const history = useHistory();
    const adminContext = useContext(AdminContext);
    const {selectedNode, changeSelectedNode} = adminContext;
    const appConfigContext = useContext(AppConfigContext);
    const {config, region, stage} = appConfigContext;
    
    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        if(config !== null || undefined){
            if (!checkFeatureEnabled(config, FEATURE_ADMIN_SCAN_AND_GO, region, stage, selectedNode)){
                history.push("/");
            }
        }
      }, [nodeId, selectedNode, config]);

    return (
        <Center>
            <Title nodeId={nodeId} />
            <Banner>
                <ScanForm nodeId={nodeId} />
            </Banner>
        </Center>
    );
}
