import AdminLayout from "./layout/AdminLayout";
import Routes from "./routes/Routes";
import { AdminContextProvider } from "../store/admin-context";
import { AppConfigContextProvider }  from "../store/app-config";


export default function App() {
  return (
    <AppConfigContextProvider>
      <AdminContextProvider>
        <AdminLayout>
          <Routes />
        </AdminLayout>
      </AdminContextProvider>
    </AppConfigContextProvider>
  );
}
