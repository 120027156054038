import React,{ useState, useContext, useEffect, useCallback } from "react";
import moment from 'moment';
import Input from "@amzn/meridian/input";
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import Select, { SelectOption } from "@amzn/meridian/select";
import AppConfigContext, { checkFeatureEnabled } from "../../../store/app-config";
import AdminContext from "../../../store/admin-context";
import { useParams , useHistory } from "react-router-dom";
import ContentLayout from "../../layout/ContentLayout";
import AdminApiFactory from '../../../admin-api/AdminApiFactory';
import Button from "@amzn/meridian/button";
import "./UploadRosterPage.scss";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import UploadRosterSubmitModal from "../../modal/UploadRosterSubmitModal";
import { 
    UPLOAD_ROSTER_PROCESSING_MESSAGE,
    ALERT_AUTHENTICATION_MESSAGE,
    UPLOAD_ROSTER_SUBMIT_ERROR_MESSAGE,
    UPLOAD_ROSTER_START_TIMES_ERROR_MESSAGE,
    UPLOAD_ROSTER_SHIFT_DURATION_ZERO,
    UPLOAD_ROSTER_SHIFT_DURATION_NOT_SET,
    FEATURE_UPLOAD_ROSTER}from "../../../constants";

export default function UploadRosterPage() {
    // This is the selector for which shiftType user chooses
    const history = useHistory();
    const adminContext = useContext(AdminContext);
    const appConfigContext = useContext(AppConfigContext);
    const {config, region, stage} = appConfigContext
    const AdminApi = AdminApiFactory();
    const {nodeId} = useParams<{ nodeId: string }>();
    const [shiftType, setShiftType] = useState();
    const [newShiftType, setNewShiftType] = useState("");
    const [startTime, setStartTime] = useState(undefined);
    const [duration, setDuration] = useState(undefined);
    const {username, selectedNode, changeSelectedNode} = adminContext;
    const [selectedFile, setSelectedFile] = useState(null);
    const [ready, setReady] = useState(false);
    const [files, setFiles] = useState([] as any);
    const [openModal, setOpenModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState<any>();
    
    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };
    const submitHandler = () => {
        let uploadRosterResponse;
        setOpenModal(true);
        setResponseMessage(UPLOAD_ROSTER_PROCESSING_MESSAGE);
    
        (async () => {
            try {
                let finalShiftType;
                if(newShiftType) {
                    finalShiftType = newShiftType.toUpperCase();
                }else {
                    finalShiftType = shiftType;
                }

                if (startTime === undefined) {
                    setResponseMessage(UPLOAD_ROSTER_START_TIMES_ERROR_MESSAGE)
                    return
                }
                if (duration === undefined) {
                    setResponseMessage(UPLOAD_ROSTER_SHIFT_DURATION_NOT_SET)
                    return
                }

                if (duration! <= 0) {
                    setResponseMessage(UPLOAD_ROSTER_SHIFT_DURATION_ZERO)
                    return
                }

                let shiftDate = moment(startTime).format('YYYY-MM-DD')
                let shiftStart = moment(startTime).format('YYYY-MM-DD HH:mm:00')
                let shiftEnd = moment(shiftStart).add(duration, "hours").format("YYYY-MM-DD HH:mm:00")
                let shiftStartEpoch = moment(shiftStart).unix()
                let shiftEndEpoch = moment(shiftEnd).unix()
                //shift id has been decided to be constant for upload roster feature
                const formattedShiftID = nodeId+":"+shiftDate+" "+"/"+" "+finalShiftType;
                uploadRosterResponse = (
                    await AdminApi.uploadRoster(nodeId, selectedFile, finalShiftType, formattedShiftID,
                        shiftDate, username, shiftStart, shiftEnd, shiftStartEpoch.toString(), shiftEndEpoch.toString())
                ).data as unknown;
                setResponseMessage(uploadRosterResponse);
            } catch(ex) {
                if (ex.response && ex.response.status === 400) {
                    console.log("400 error message:"+ex.response.data);
                    setResponseMessage(ex.response.data);
                } else if (ex.response && ex.response.status === 403) {
                    console.log("error message:"+ex.response.data);
                    setResponseMessage(ALERT_AUTHENTICATION_MESSAGE);
                } else {
                    console.log("error message:"+ex.response.data);
                    setResponseMessage(UPLOAD_ROSTER_SUBMIT_ERROR_MESSAGE);
                }
            }
        })();
    }
    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        if(config !== null || undefined){
            if (!checkFeatureEnabled(config, FEATURE_UPLOAD_ROSTER, region, stage, selectedNode)){
                history.push("/");
            }
        }
      }, [nodeId, selectedNode, config]);


    
    const onConfirmClose = useCallback(() => setOpenModal(false), []);

    return(
        <ContentLayout nodeId={nodeId} helperText="" subTitle="Upload Roster">
            <UploadRosterSubmitModal
                open={openModal}
                responseMessage={responseMessage}
                onClose={onConfirmClose}
            />
            <Box width={500} minHeight={75}>
            <label className="textColor">Please upload roster csv file with BadgeId, EmployeeName, EmployeeLogin, EmployeeID, AssignedRole fields</label>
            </Box>
            <Row>
                <Box width={200} >Shift Type</Box>
                <Box>
                    <Select
                        value={shiftType}
                        onChange={setShiftType}
                        placeholder=""
                        width={300}
                        size="medium"
                        data-type="shiftType">
                    <SelectOption value="DAY" label="DAY" />
                    <SelectOption value="NIGHT" label="NIGHT" />
                    <SelectOption value="TWI" label="TWI" />
                    <SelectOption value="Create new" label="Create new" />
                    </Select>
                </Box>
            </Row>
            <Row>
                <Box width={200} >Shift Start Date and Time</Box>
                <Box width={300}>
                    <DatePicker
                        placeholderText="--:--"
                        selected={startTime}
                        filterTime={filterPassedTime}
                        onChange={(startTime) => setStartTime(startTime)}
                        timeIntervals={15}
                        minDate={new Date()}
                        maxDate={addDays(new Date(), 3)}
                        dateFormat="yyyy-MM-dd HH:mm:00"
                        showTimeSelect
                        customInput={<input data-type="shiftStart" type="text" />}
                    />
                </Box>
            </Row>
            <Row>
                <Box width={200} >Shift Duration</Box>
                <Box>
                    <Input
                        placeholder={"in Hours"}
                        value={duration}
                        type={"number"}
                        onChange={(duration) => setDuration(duration)}
                        width={300}
                        data-type="shiftDuration"/>
                </Box>
            </Row>

                {(() => {
                if(shiftType === "Create new")
                {return(
                    <Row>
                        <Box width={200} >New Shift Type</Box>
                        <Box>
                            <Input
                                value={newShiftType}
                                width={300}
                                size="medium"
                                placeholder="Enter shift type"
                                onChange={setNewShiftType}
                                data-type="shiftCreate"/>
                        </Box>
                    </Row>
                )
                }
                })()}
            <Box>
                <div className="meridian-override-upload-box">
                    <span className="meridian-css-span-1">
            <FileInput
                type="single"
                accept=".csv"
                onFileAttached={(acceptedFiles) => {
                    acceptedFiles.forEach((file) => {
                        console.log("Filename", file.name);
                        const fileReader = new FileReader();
                        fileReader.readAsText(file);
                        fileReader.onload = function () {
                            const arrayBuffer: any = fileReader.result;
                            setSelectedFile(arrayBuffer);
                            setReady(true);
                        };
                        file.percentage = 100;
                    });
                    setFiles([...files, ...acceptedFiles]);
                }}
                uploadButtonSize="large"
                uploadButtonLabel="Drag file here to upload"
                showDropZone={true}
                dropZoneLabel="Click to browse"
                dropZoneDirection="column"
                uploadButtonType={"link"}
                data-type="csvUpload"
            >

                {files.map((file) => {
                    return (
                        <FileDetails
                            error={file.error}
                            errorMessage={file.errorMessage}
                            file={file}
                            connectionSpeed="5MB/s"
                            uploadPercentage={file.percentage}
                            key={file.name}
                            cancelUploadButtonLabel={`Cancel, ${file.name}`}
                            onClickCancel={() => {
                                file.cancel();
                                const updatedFileArr = files.filter(
                                    (fl) => fl.name !== file.name
                                );
                                setFiles(updatedFileArr);
                            }}
                            removeFileButtonLabel={`Delete, ${file.name}`}
                            onClickRemoveFile={() => {
                                const updatedFileArr = files.filter(
                                    (fl) => fl.name !== file.name
                                );
                                setFiles(updatedFileArr);
                            }}
                            uploadComplete={file.percentage === 100}
                        />
                    );
                })}
            </FileInput>
                    </span>
                </div>
            </Box>
            <Row>
                <div className="meridian-override-row-button">
                    <Button type="primary" size="medium" disabled={!files[0]} submit={ready} onClick={submitHandler} data-type="submitRoster">Submit Roster</Button>
                </div>
            </Row>

        </ContentLayout>
    );
}
