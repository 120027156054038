import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { cloneDeep } from 'lodash';
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu";
import homeIconTokens from "@amzn/meridian-tokens/base/icon/home";
import cogIconTokens from "@amzn/meridian-tokens/base/icon/cog";
import dashboardIconTokens from "@amzn/meridian-tokens/base/icon/dashboard";
import clockIconTokens from "@amzn/meridian-tokens/base/icon/clock";
import {
  HOME_LINK_LABEL,
  CONFIGURATION_LINK_LABEL,
  CONFIGURATION_CALM_CODE_LABEL,
  SIDE_MENU_HASH_KEY,
  SIDE_MENU_OPEN_HASH_KEY,
  CONFIGURATION_UPLOAD_ROSTER_LABEL,
  AUDIT_LINK_LABEL, CONFIGURATION_ADD_CERTIFICATE_LABEL, LMS_MANUAL_LABOR_TRACK_LINK_LABEL,
  KIOSK_LABOR_TRACK_LINK_LABEL,
  FEATURE_UPLOAD_ROSTER,
  FEATURE_ADMIN_SCAN_AND_GO,
  FEATURE_LMS_MANUAL_LABOR_TRACK,
} from "../../constants";
import AdminContext from "../../store/admin-context";
import AppConfigContext, { checkFeatureEnabled } from "../../store/app-config";

const NAVLINK_HASH = {
  home: "#home",
  configuration: "#configuration",
  calmCode: "#calmCode",
  addCertificates: "#addCertificates",
  lmsManualLaborTrack: "#lmsManualLaborTrack",
  audit: "#audit",
  kiosk: "#kioskLaborTrack",
  uploadRoster: "#uploadRoster",
};

const NAVLINK_HASH_OBJECTS = [
  {
    hashName: NAVLINK_HASH.home,
    label: HOME_LINK_LABEL,
    iconTokens: homeIconTokens,
    isNested: false,
    visible: true,
    hrefLink: "/",
  },
  {
    hashName: NAVLINK_HASH.configuration,
    label: CONFIGURATION_LINK_LABEL,
    iconTokens: cogIconTokens,
    isNested: true,
    visible: true,
    hrefLink: "/config",
    nestedLinks: [
      {
        hashName: NAVLINK_HASH.calmCode,
        label: CONFIGURATION_CALM_CODE_LABEL,
        hrefLink: "/calm-code",
        visible: true,
      },
      {
        hashName: NAVLINK_HASH.addCertificates,
        label: CONFIGURATION_ADD_CERTIFICATE_LABEL,
        hrefLink: "/add-certificates",
        visible: false,
      },
      {
        hashName: NAVLINK_HASH.uploadRoster,
        label: CONFIGURATION_UPLOAD_ROSTER_LABEL,
        hrefLink: "/upload-roster",
        visible: false,
      }
    ]
  },
  {
    hashName: NAVLINK_HASH.kiosk,
    label: KIOSK_LABOR_TRACK_LINK_LABEL,
    iconTokens: dashboardIconTokens,
    isNested: false,
    visible: false,
    hrefLink: "/kiosk-labor-track"
  },
  {
    hashName: NAVLINK_HASH.lmsManualLaborTrack,
    label: LMS_MANUAL_LABOR_TRACK_LINK_LABEL,
    iconTokens: dashboardIconTokens,
    isNested: false,
    visible: false,
    hrefLink: "/lms-manual-labor-track"
  },
  {
    hashName: NAVLINK_HASH.audit,
    label: AUDIT_LINK_LABEL,
    iconTokens: clockIconTokens,
    isNested: false,
    visible: true,
    hrefLink: "/audit"
  }
];

const PATH_NAMES_HASH_ARRAY = [{
  hrefLink: "/audit",
  hashName: NAVLINK_HASH.audit,
},

  {
    hrefLink: "/lms-manual-labor-track",
    hashName: NAVLINK_HASH.lmsManualLaborTrack,
  },
  {
    hrefLink: "/add-certificates",
    hashName: NAVLINK_HASH.addCertificates,
  },
  {
    hrefLink: "/calm-code",
    hashName: NAVLINK_HASH.calmCode,
  },
];

export default function AdminSideMenu() {
  const location = useLocation();
  const history = useHistory();
  const adminContext = useContext(AdminContext);
  const appConfigContext = useContext(AppConfigContext);

  const { selectedNode, isSideBarOpen } = adminContext;
  const { config, stage, region } = appConfigContext;

  const [navHashObjects, setNavHashObjects] = useState(cloneDeep(NAVLINK_HASH_OBJECTS));
  const [hash, setHash] = useState(getDefaultHash(location));
  const [openLinkHash, setOpenLinkHash] = useState(localStorage.getItem(SIDE_MENU_OPEN_HASH_KEY) || null);

  useEffect(() => {
    loadAppConfigAndFeatures()
  }, [selectedNode, config])

  function loadAppConfigAndFeatures(){
    // Make sure AppConfig is loaded before we do any changes.
    if(config !== null){
      if(selectedNode !== undefined || null){
        let clonedState = cloneDeep(navHashObjects)
        addFeatureToNavBar( FEATURE_UPLOAD_ROSTER, "#configuration", true, clonedState, "#uploadRoster");
        addFeatureToNavBar( FEATURE_LMS_MANUAL_LABOR_TRACK, "#configuration", true, clonedState, "#addCertificates");
        addFeatureToNavBar( FEATURE_LMS_MANUAL_LABOR_TRACK, "#lmsManualLaborTrack", false, clonedState)
        addFeatureToNavBar( FEATURE_ADMIN_SCAN_AND_GO, "#kioskLaborTrack", false, clonedState)
        setNavHashObjects(clonedState);
      }
    }
  }
  
  function addFeatureToNavBar(featureName: string, linkHashName: string, isNestedValue: boolean,  clonedState: any, nestedHashName?: string,){
    if(checkFeatureEnabled(config, featureName, region, stage, selectedNode)){
      if(isNestedValue){
        changeNestedNavObjectVisibleFlag(clonedState, linkHashName, true, nestedHashName! )
      }else{
        changeNavObjectVisibleFlag(clonedState, linkHashName, true)
      }
    }else{
      if(isNestedValue){
        changeNestedNavObjectVisibleFlag(clonedState, linkHashName, false, nestedHashName! )
      }else{
        changeNavObjectVisibleFlag(clonedState, linkHashName, false)
      }
    }
  }
 
  function changeNestedNavObjectVisibleFlag(config: any, hashName: string, visible: boolean , nestedHashName: string){
      config.forEach((key: string, value: any) => {
        if(key["hashName"] === hashName){
          key["nestedLinks"].forEach((k: string, v: any) => {
            if(k["hashName"] === nestedHashName){
              k["visible"] = visible;
            }
          })
        }
      })
      return config
  }

  function changeNavObjectVisibleFlag(config: any, hashName: string, visible: boolean){
    config.forEach((key: string, value: any) => {
      if(key["hashName"] === hashName){
        key["visible"] = visible;
      }
    })
    return config
  };
  
  function getDefaultHash(location) {
    let localStoredHash = localStorage.getItem(SIDE_MENU_HASH_KEY);
    let locationHash = getLocationHashFromPath(location.pathname);
    if (localStoredHash === locationHash) {
      return localStoredHash;
    } else {
      localStorage.setItem(SIDE_MENU_HASH_KEY, locationHash);
      return locationHash;
    }
  }

  function getLocationHashFromPath(pathname) {
    for (let linkItem of PATH_NAMES_HASH_ARRAY) {
      if (pathname.includes(linkItem.hrefLink)) {
        return linkItem.hashName;
      }
    }
    return NAVLINK_HASH.home;
  }
    
    return (
        <SideMenu open={isSideBarOpen}>
          {navHashObjects.map(({visible, isNested, hashName, label, iconTokens, hrefLink, nestedLinks = []}) => {
            if (isNested) {
              return visible ? <SideMenuLink
                  key={hashName}
                  href={hashName}
                  selected={hash === hashName}
                  open={openLinkHash === hashName}
                  onClick={(hashValue) => {
                    setHash(hashValue);
                    localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                    let nextOpenLinkHash = openLinkHash === hashName ? null : hashValue;
                    setOpenLinkHash(nextOpenLinkHash);
                    localStorage.setItem(SIDE_MENU_OPEN_HASH_KEY, nextOpenLinkHash);
                  }}
                  disabled={!selectedNode}
                  icon={iconTokens}
              >
                {label}
                {nestedLinks.map((nestedLink) => (
                    nestedLink['visible'] ? <SideMenuLink
                        key={nestedLink['hashName']}
                        href={nestedLink['hashName']}
                        selected={hash === nestedLink['hashName']}
                        disabled={!selectedNode}
                        onClick={(hashValue) => {
                          setHash(hashValue);
                          localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                          history.push(`/${selectedNode}${hrefLink}${nestedLink['hrefLink']}`);
                        }}
                    >
                      {nestedLink['label']}
                    </SideMenuLink> : null
                ))}
              </SideMenuLink> : null
            } else {
              return visible ? <SideMenuLink
                  key={hashName}
                  href={hashName}
                  selected={hash === hashName}
                  onClick={(hashValue) => {
                    setHash(hashValue);
                    localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                    history.push(selectedNode ? `/${selectedNode}${hrefLink}` : "/");
                  }}
                  disabled={hashName === NAVLINK_HASH.home ? false : !selectedNode}
                  icon={iconTokens}
              >{label}</SideMenuLink> : null
            }
          })}
        </SideMenu>
    );
}
