import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import "./ContentLayout.scss";

export default function ContentLayout({ nodeId, helperText, children, subTitle="" }) {
  return (
    <div className="contentContainer">
      <Column height="100%" heights="fit">
        <Box>
          <span className="titleNodeId">{nodeId} <span className="subTitle">{subTitle}</span></span>
        </Box>
        <Box>
          <Text>{helperText}</Text>
        </Box>
        {children}
      </Column>
    </div>
  );
}
