import {
  AppConfigDataClient,
  GetLatestConfigurationCommand,
  StartConfigurationSessionCommand,
} from "@aws-sdk/client-appconfigdata";
import { fromWebToken } from "@aws-sdk/credential-providers";
import { getMidwayJwtToken } from "../auth/MidwayJwtToken";
import { APP_CONFIG_CONFIGURATIONS } from "../constants";

export async function AppConfig(region: string, stage: string) {
  const client = new AppConfigDataClient({
    region: region,
    credentials: fromWebToken({
      // Required. ARN of the role that the caller is assuming.
      roleArn: APP_CONFIG_CONFIGURATIONS[stage][region].ROLE_NAME,
      // Required. The OAuth 2.0 access token or OpenID Connect ID token that is provided by the
      // identity provider.
      webIdentityToken: await getMidwayJwtToken(),
      roleSessionName: `${region}-${stage}-AppConfig-FastStart`,
      durationSeconds: 3600,
    }),
  });
  
  const startSession = await client.send(
    new StartConfigurationSessionCommand({
      ApplicationIdentifier: APP_CONFIG_CONFIGURATIONS[stage][region].APPLICATION_ID,
      EnvironmentIdentifier: APP_CONFIG_CONFIGURATIONS[stage][region].ENVIRONMENT_ID,
      ConfigurationProfileIdentifier: APP_CONFIG_CONFIGURATIONS[stage][region].CONFIGURATION_PROFILE_ID,
    })
  );
  const { InitialConfigurationToken } = startSession;

  const getConfig = await client.send(
    new GetLatestConfigurationCommand({
      ConfigurationToken: InitialConfigurationToken,
    })
  );

  return getConfig.Configuration!.transformToString();
}


