import { useEffect, useState, createContext } from "react";
import { AppConfig } from "../config/AppConfig";
import { Stage, Region, BASE_DOMAIN_URL, DEV_ENABLED, DEV_REGION } from "../constants";

interface appConfigStateProps {
  config: any;
  stage: string | null;
  region: string | null;
  error: boolean | null;
}

const initialState: appConfigStateProps = {
  config: null,
  stage: null,
  region: null,
  error: false,
};

const windowLocation = window.location.hostname;
const AppConfigContext = createContext(initialState);

export const AppConfigContextProvider = ({ children }) => {
 const [appConfig, setAppConfig] = useState(null);
 const [error, setError] = useState(false);

 useEffect(() => {
  retrieveAppConfig(determineRegion(), determineStage(), 3, 1000);
}, [])

 function retrieveAppConfig(region, stage, retry, delay){
  Promise.resolve(AppConfig(determineRegion(), determineStage()))
  .then((value) => {
    setAppConfig(JSON.parse(value))
  })
  .catch((error) => {
      retry -= 1
      if(retry > 0){
        console.log(`Failed to retrieve AppConfig, retry fetch in ${delay} ms....`)
        setTimeout(function () {
          retrieveAppConfig(determineRegion(), determineStage(), retry, delay * 2);
        }, delay)
      }else{
        setError(true);
        console.log(`Unable to retrieve AppConfig...`)
      }
  });
 }
  const context: appConfigStateProps = {
    config: appConfig,
    stage: determineStage(),
    region: determineRegion(),
    error: error,
  };

  return (
    <AppConfigContext.Provider value={context}>{children}</AppConfigContext.Provider>
  );
};


export function determineStage() {
  if(!(windowLocation).includes(BASE_DOMAIN_URL)){
      return Stage.TEST;
  }else if((windowLocation).includes(Stage.BETA) && (windowLocation).includes(BASE_DOMAIN_URL)){
     // Ternary for handling distinction for aws deployed environment in the TEST stage
     // FastStart domain falls under admin.{login}.beta.faststart.ats.amazon.dev
     // constants/index.tsx -> DEV_ENABLED should be set to true if testing in your local aws account with appconfig.
     return DEV_ENABLED ? Stage.TEST : Stage.BETA
  }else{
      return Stage.PROD;
  }
}

export function determineRegion() {
   if(windowLocation.includes(Region.EU_WEST_1)){
      return Region.EU_WEST_1
   }
   // Ternary for determining if developer is deployed in a different region on local or aws account.
   return DEV_ENABLED ? DEV_REGION : Region.US_EAST_1; 
}

export function checkFeatureEnabled(config, key, region, stage, node): boolean { 
 if(config[key][region][stage].enabled){
    return true
 }else if(config[key][region][stage].nodesEnabled.includes(node)){
     return true
 }else{
     return false
 };
}

export default AppConfigContext;

