import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentLayout from "../../layout/ContentLayout";
import CalmCodeTable from "./CalmCodeTable";
import {
  ALERT_DEFAULT_MESSAGE,
  CALM_CODE_DISPLAY_TEXT,
  CALM_CODE_FIELD_NAME,
  CALM_CODE_LABEL,
  DEFAULT_SORT_COLUMN,
  DEFAULT_SORT_DIRECTION,
  DELAY_IN_MIN_FIELD_NAME,
  DELAY_IN_MIN_LABEL,
  ROLE_NAME_FIELD_NAME,
  ROLE_NAME_LABEL, ROLE_TYPE_FIELD_NAME,
  ROLE_TYPE_LABEL,
} from "../../../constants";
import AdminContext from "../../../store/admin-context";
import AdminApiFactory from "../../../admin-api/AdminApiFactory";
import { MappingInfo } from "../../../admin-api/generated-src";
import Loader from "@amzn/meridian/loader";
import orderBy from "lodash/orderBy";
import AlertErrorWithMessage from "../../alert/AlertErrorWithMessage";

export default function CalmCodePage() {
  const adminContext = useContext(AdminContext);
  const { selectedNode, changeSelectedNode, isEUDomain } = adminContext;
  const [sargRoleList, setSargRoleList] = useState<MappingInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(ALERT_DEFAULT_MESSAGE);
  const { nodeId } = useParams<{ nodeId: string }>();

  const AdminApi = AdminApiFactory();

  const cleanup = () => {
    setSargRoleList([]);
    setIsLoading(false);
    setShowAlert(false);
    setAlertMessage(ALERT_DEFAULT_MESSAGE);
  };

  const TABLE_TITLE_NA = [
    { headerName: ROLE_NAME_LABEL, sortId: ROLE_NAME_FIELD_NAME},
    { headerName: ROLE_TYPE_LABEL, sortId: ROLE_TYPE_FIELD_NAME },
    { headerName: CALM_CODE_LABEL, sortId: CALM_CODE_FIELD_NAME},
    {headerName: DELAY_IN_MIN_LABEL, sortId: DELAY_IN_MIN_FIELD_NAME},
    {
      headerName: 'Action',
      sortId: '',
      columnSpan: 2,
    }
  ];

  const TABLE_TITLE_EU = [
    { headerName: ROLE_NAME_LABEL, sortId: ROLE_NAME_FIELD_NAME},
    { headerName: CALM_CODE_LABEL, sortId: CALM_CODE_FIELD_NAME},
    {headerName: DELAY_IN_MIN_LABEL, sortId: DELAY_IN_MIN_FIELD_NAME},
    {
      headerName: 'Action',
      sortId: '',
      columnSpan: 2,
    }
  ];

  // get nodeId from url
  useEffect(() => {
    if (nodeId !== selectedNode) {
      changeSelectedNode(nodeId);
    }
  }, [nodeId]);

  // load SARG Roles for specific nodeId
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const sargRoles: MappingInfo[] = (
          await AdminApi.getSargRoleCalmCodeMapping(nodeId)
        ).data as MappingInfo[];
        if (sargRoles && sargRoles.length) {
          setSargRoleList(
            orderBy(
              sargRoles,
              [DEFAULT_SORT_COLUMN],
              [DEFAULT_SORT_DIRECTION === "descending" ? "desc" : "asc"]
            )
          );
        } else {
          setShowAlert(true);
          setAlertMessage("No Sarg Roles found.");
        }
      } catch (ex) {
        console.error(`Error fetching /admin/sargRole/${nodeId}`, ex);
        setShowAlert(true);
        if (ex && ex.message) {
          setAlertMessage(ex.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();

    return () => cleanup();
  }, [nodeId]);

  return (
    <ContentLayout nodeId={nodeId} helperText={CALM_CODE_DISPLAY_TEXT}>
      {isLoading ? (
        <Loader />
      ) : showAlert ? (
        <AlertErrorWithMessage message={alertMessage} />
      ) : isEUDomain ? (
        <CalmCodeTable sargRoleList={sargRoleList} titles={TABLE_TITLE_EU} showRoleType={false}  />
      ) : (<CalmCodeTable sargRoleList={sargRoleList} titles={TABLE_TITLE_NA} showRoleType={true} />
      )}
    </ContentLayout>
  );
}
