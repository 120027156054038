import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Column from "@amzn/meridian/column";
import AlertErrorWithMessage from "../alert/AlertErrorWithMessage";
import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading";
import Loader from "@amzn/meridian/loader";
import NodeIdSelect from "../nodeIdSelect/NodeIdSelect";
import {
  WELCOME_MESSAGE,
  NODE_ID_LABEL_TEXT,
  NODE_LIST_KEY,
  ALERT_DEFAULT_MESSAGE,
  ALERT_AUTHENTICATION_MESSAGE,
  USER_NAME_KEY
} from "../../constants";
import AdminContext from "../../store/admin-context";
import "./styles.scss";
import AdminApiFactory from "../../admin-api/AdminApiFactory";
import AppConfigContext from "../../store/app-config";

interface AdminDetailsWithNodeInfo {
  login: string;
  nodes: string[];
}

export default function Homepage() {
  const adminContext = useContext(AdminContext);
  const appConfigContext = useContext(AppConfigContext);
  const { config, error } = appConfigContext;
  const { selectedNode, changeSelectedNode, loadNodeList, changeUsername } =
    adminContext;
  const { nodeId } = useParams<{ nodeId: string }>();
  const AdminApi = AdminApiFactory();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(ALERT_DEFAULT_MESSAGE);
  const [isLoading, setIsLoading] = useState(false);

  // make sure the nodeId from url is in sync with context
  useEffect(() => {
    if (nodeId !== selectedNode) {
      changeSelectedNode(nodeId);
    }
    if(config !== null){
      setAlertMessage(ALERT_DEFAULT_MESSAGE)
      setShowAlert(false)
    }
    if(error){
      setAlertMessage("There was an error loading application features.")
      setShowAlert(true)
    }
  }, [nodeId, error, config]);

  // load admin info
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const adminLoginObject: AdminDetailsWithNodeInfo = (
          await AdminApi.getAdminInfoWithNodeDetails()
        ).data as AdminDetailsWithNodeInfo;
        if (adminLoginObject) {
          const { nodes, login } = adminLoginObject;
          localStorage.setItem(NODE_LIST_KEY, JSON.stringify(nodes));
          localStorage.setItem(USER_NAME_KEY, login);
          loadNodeList(nodes);
          changeUsername(login);
          setShowAlert(false);
        } else {
          setShowAlert(true);
        }
      } catch (ex: any) {
        console.error("Error loading home page", ex);
        setShowAlert(true);
        // special check for 403, probably due to missing LDAP roles
        if (ex.response && ex.response.status === 403) {
          setAlertMessage(ALERT_AUTHENTICATION_MESSAGE);
        } else if (ex.message) {
          setAlertMessage(ex.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      setShowAlert(false);
      setIsLoading(false);
      setAlertMessage(ALERT_DEFAULT_MESSAGE);
    };
  }, []);

  // using Meridian grid system to center element, Box is place holder, please do not delete.
  return (
    <div className="welcomeDiv">
      <Column
        alignmentHorizontal="center"
        height={450}
        heights={["20%", "fit", "fill"]}
        width="95%"
        backgroundColor="secondary"
        spacingInset="400"
      >
        <Box>
          {showAlert && <AlertErrorWithMessage message={alertMessage} />}
        </Box>
        <Box>
          <Heading level={2}>{WELCOME_MESSAGE}</Heading>
        </Box>
        <Box>
          {isLoading ? (
            <Loader />
          ) : (
            <NodeIdSelect width={300} label={NODE_ID_LABEL_TEXT} />
          )}
        </Box>
      </Column>
    </div>
  );
}
