import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    ADD_CERTIFICATE_DISPLAY_TEXT,
    ALERT_DEFAULT_MESSAGE,
    ALERT_AUTHENTICATION_MESSAGE,
    DEFAULT_SORT_COLUMN,
    DEFAULT_SORT_DIRECTION,
    FEATURE_LMS_MANUAL_LABOR_TRACK
} from "../../../constants";
import AddCertificatesForm from "./AddCertificatesForm";
import AdminContext from "../../../store/admin-context";
import LMSTableContext from "../../../store/lms-store";
import ContentLayout from "../../layout/ContentLayout";
import LMSTable from "./LMSTable";
import AdminApiFactory from "../../../admin-api/AdminApiFactory";
import { LMSLaborTrackingRole } from "../../../admin-api/generated-src";
import Loader from "@amzn/meridian/loader";
import orderBy from "lodash/orderBy";
import AlertErrorWithMessage from "../../alert/AlertErrorWithMessage";
import AppConfigContext, { checkFeatureEnabled } from "../../../store/app-config";


export default function AddCertificatesPage() {
    const history = useHistory();
    const adminContext = useContext(AdminContext);
    const lmsTableContext = useContext(LMSTableContext);
    const { selectedNode, changeSelectedNode } = adminContext;
    const appConfigContext = useContext(AppConfigContext);
    const {config, region, stage} = appConfigContext
    const { lmsCertificateList, setLmsCertificateList, isLmsTableLoading, setIsLmsTableLoading, reloadLmsList } = lmsTableContext;
    const { nodeId } = useParams<{ nodeId: string }>();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(ALERT_DEFAULT_MESSAGE);

    const AdminApi = AdminApiFactory();

    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        if(config !== null || undefined){
            if (!checkFeatureEnabled(config, FEATURE_LMS_MANUAL_LABOR_TRACK, region, stage, selectedNode)){
                history.push("/");
            }
        }
    }, [nodeId, selectedNode, config]);

    useEffect(() => {
        setIsLmsTableLoading(true);
        (async () => {
            try {
                const lmsCertificatesResponse: LMSLaborTrackingRole[] = (
                    await AdminApi.getLmsCertificatesAndCustomRolesForSite(nodeId)
                ).data as LMSLaborTrackingRole[];
                if (lmsCertificatesResponse && lmsCertificatesResponse.length) {
                    setLmsCertificateList(orderBy(
                        lmsCertificatesResponse,
                        [DEFAULT_SORT_COLUMN],
                        [DEFAULT_SORT_DIRECTION === "descending" ? "desc" : "asc"]
                    ));
                }
            } catch (ex: any) {
                console.error(`Error GET /admin/lmsCertificate/${nodeId}`, ex);
                setShowAlert(true);
                if (ex.response && ex.response.status === 403) {
                    setAlertMessage(ALERT_AUTHENTICATION_MESSAGE);
                } else if (ex.message) {
                    setAlertMessage(ex.message);
                }
            } finally {
                setIsLmsTableLoading(false);
            }
        })();

        return () => {
            setShowAlert(false);
            setAlertMessage(ALERT_DEFAULT_MESSAGE);
        };
    }, [nodeId, reloadLmsList]);

    return (
        <ContentLayout nodeId={nodeId} helperText={ADD_CERTIFICATE_DISPLAY_TEXT}>
            {showAlert && <AlertErrorWithMessage message={alertMessage} />}
            {!showAlert && <AddCertificatesForm nodeId={nodeId} />}
            {!showAlert && (isLmsTableLoading ? <Loader /> : <LMSTable lmsCertificateList={lmsCertificateList} />)}
        </ContentLayout>
    );
}
