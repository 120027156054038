import AppLayout from "@amzn/meridian/app-layout";
import AdminMasthead from "../adminMasthead/AdminMasthead";
import AdminSideMenu from "../adminSideMenu/AdminSideMenu";
import Footer from "../footer/Footer";

export default function AdminLayout({ children }) {

  return (
    <AppLayout
      headerComponent={AdminMasthead}
      sidebarComponent={AdminSideMenu}
      footerComponent={Footer}
      backgroundColor="primary"
    >
      <AdminMasthead />
      <AdminSideMenu />
      {children}
      <Footer />
    </AppLayout>
  );
}
